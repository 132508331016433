const messages = {
    nl: {
        message: {
            scenarioLocationsVisibleOnMap: 'Locaties van scenarios',
            scenarios: 'Scenarios',
            errorOccured: 'Nou, er is iets misgegaan. Er heeft zich een onverwachte fout voorgedaan, mogelijk als gevolg van een slechte internetverbinding. Sluit dit bericht en probeer de actie opnieuw. Als het probleem aanhoudt, vernieuw dan de pagina. Blijft de fout zich voordoen, controleer dan je internetverbinding of neem contact op met de ondersteuning voor hulp.',
            noTopScenariosFound: 'Er zijn geen top scenarios gevonden. Maak een nieuwe top scenario aan of pas een bestaand scenario aan zodat deze top scenario is.',
            filterScenarios: 'Filter op naam',
            locationInDefaultScenario: 'U kunt het default scenario niet aanpassen. Als u doorgaat wordt de locatie aangemaakt in een nieuw scenario met dezelfde naam als de locatie. Wilt u doorgaan?',
            generatedOn: 'Gegenereerd op',
            foundLocations: 'Gevonden locaties',
            of: 'van',
            page: 'Pagina',
            selectAll: 'alles',
            selectNone: 'geen',
            heatMaps: 'Warmtekaart',
            print: 'Afdrukken',
            no: 'Nee',
            yes: 'Ja',
            copyChangesInformation: 'Hieronder kunt u aangeven welke locaties worden gekopieerd naar het doel scenario.',
            locations: 'Locaties',
            copyChanges: 'Wijzigingen kopiëren',
            nonShared: 'Niet gedeeld',
            sharedWith: 'Gedeeld met',
            otherLocations: 'andere locaties',
            mergeScenarioSource: 'De volgende wijzigingen zullen worden samengevoegd in het opgegeven scenario.',
            targetScenario: 'Doel scenario',
            deleteSourceScenario: 'Verwijder het bron scenario',
            isTopScenarioDescription: 'Een top scenario zorgt er voor dat het scenario als eerste en vet gedrukt wordt weergegeven in de lijst met scenarios.',
            mergeScenario: 'Scenario samenvoegen',
            isTopScenario: 'Top scenario',
            confirmClose: 'Weet u zeker dat u dit scherm wilt sluiten?',
            displayName: 'Weergave naam',
            email: 'E-mail adres',
            mobilePhone: 'Mobiel telefoonnummer',
            calculationModel: 'Calculation model',
            calculateUnprocessedScenarios: 'Doorrekenen onverwerkte scenarios',
            log: 'Log',
            warning: 'Waarschuwing',
            eventType: 'Type',
            source: 'Bron',
            message: 'Bericht',
            fetchIsoLine: 'Isolijn ophalen',
            isoLine: 'Isolijn',
            scheduledDateTimeUtc: 'Gepland',
            isoLinesMissingDateTimeUtc: 'Missende rijtijden',
            startedDateTimeUtc: 'Start',
            endedDateTimeUtc: 'Einde',
            noMatchingLocations: 'Geen overeenkomstige locaties',
            scenarioNameIsRequired: 'Scenario naam is verplicht',
            parentScenario: 'Bron scenario',
            sourceLocations: 'Bron locaties',
            sourceLocationsAllScenarios: 'Bron locaties alle scenarios',
            driverFilterFunction: 'Functie',
            driverFilterTitle: 'Titel',
            addDriver: 'Verklaring toevoegen',
            addDriverFilter: 'Groep toevoegen',
            confirmRemoveScenario: 'Weet u zeker dat u dit scenario wilt verwijderen?',
            confirmRemoveLocation: 'Weet u zeker dat u deze locatie wilt verwijderen?',
            confirmUndoCloseLocation: 'Weet u zeker dat u de sluiting van deze locatie ongedaan wilt maken?',
            confirmUndoEditLocation: 'Weet u zeker dat u de bewerkingen van deze locatie ongedaan wilt maken?',
            editScenario: 'Scenario bewerken',
            removeScenario: 'Scenario verwijderen',
            calculateScenario: 'Scenario doorrekenen',
            filterAndSearch: 'Filteren en zoeken',
            map: 'Kaart',
            matchingPercentage: '% Overeenkomst',
            drawerNotCalculated: 'Het model is niet doorgerekend. Daardoor zijn de resultaten niet zichtbaar.',
            drawerCalculating: 'Het model is aan het rekenen. Wacht tot de resultaten zichtbaar worden.',
            legend: 'Legenda',
            modelAdmin: 'Model beheerder',
            globalAdmin: 'Hoofd beheerder',
            noChanges: 'Er zijn nog geen openingen of sluitingen.',
            name: 'Naam',
            query: 'Zoek-tekst',
            export: 'Exporteer',
            noDrivers: 'Er zijn geen verklaringen gedefinieerd voor dit model.',
            condition: 'Voorwaarde',
            roundingDigits: 'Decimalen',
            inverse: 'Achterstevoren',
            drivers: 'Verklaringen',
            report: 'rapport',
            matchingLocationsOutputChart: 'Resultaten diagram',
            matchingLocationsSimilarityChart: 'Overeenkomsten diagram',
            driversChart: 'Verklaring diagram',
            disclaimer: 'Disclaimer',
            disclaimerContent: 'De ontwikkeling van IRIS is gebaseerd op wetenschappelijke methoden, actuele verkooppunt informatie en zorgvuldig onderzochte en up-to-date klantinformatie. Het model wordt zo goed mogelijk gevalideerd op basis van de actuele omzetten die in de markt worden gerealiseerd en informatie over het functioneren van de markt. Ieder wiskundig model, hoe gedetailleerd ook in de uitwerking, is een vereenvoudigde weergave van de werkelijkheid en zal nooit 100% correct zijn. Aan de uitkomsten kunnen daarom geen rechten worden ontleend.',
            help: 'Handleiding',
            csvExport: 'Export data',
            fileFormatCulture: 'CSV taalinstelling',
            dutchFormat: 'Nederlands formaat',
            englishFormat: 'Engels formaat',
            areaInformation: 'Gebiedsinformatie',
            adminPages: 'Admin',
            locationAreas: 'Gebieden van de locatie',
            adminOnly: 'Alleen voor beheerders (WERKT NOG NIET)',
            scenarioCompetition: 'Locaties in dit scenario concureren met elkaar.',
            scenarioProcessingLog: 'Verwerking logboek ',
            calculationScheduled: 'Verwerking ingepland',
            calculationWaitingForIsolines: 'Verwerking wacht op isochronen',
            calculationStarted: 'Verwerking start',
            calculationEnded:'Verwerking einde',
            calculationScenarioError: 'Verwerking fout (scenario)',
            calculationModelError: 'Verwerking fout (model)',
            isolinesStarted: 'Isochronen ophalen start',
            isolinesEnded: 'Isochronen ophalen einde',
            isolinesError: 'Isochronen ophalen fout',
            isolinesBusy: 'Isochronen ophalen bezig',
            dateAndTime: 'Datum en tijd',
            action: 'Actie',
            model: 'Model',
            user: 'Gebruiker',
            last100records: 'Laatste 100 records',
            propertiesChart: 'XY grafiek',
            propertyX: 'Eigenschap op X as',
            propertyY: 'Eigenschap op Y as',
            tree: 'boom | bomen',
            applicationTitle: 'IRIS',
            title: 'Titel',
            logout: 'Afmelden',
            login: 'Aanmelden',
            share: 'Aandeel',
            area: 'Gebied | Gebieden',
            personType: 'Persona',
            inhabitants: 'Inwoners',
            houseHolds: 'Huishoudens',
            income: 'Inkomen',
            areaRevenuePerYear: 'Besteding in postcode per jaar',
            areaRevenuePerPeriod: 'Besteding in postcode per periode',
            areaStandardConsumers: 'Standaard consumenten in postcode',
            total: 'Totaal',
            travelTimeScores: 'Aantrekkelijkheden reistijd',
            from: 'vanaf',
            calculation: 'Berekening',
            perYear: 'Per jaar',
            catchmentArea: 'Verzorgingsgebied',
            outputSpending: 'Besteding',
            outputNumber: 'Aantal',
            car: 'Auto',
            pedestrian: 'Voetganger',
            scorePersonType: 'Aantrekkelijkheid persona',
            calculated: 'Berekend',
            location: 'Locatie',
            value: 'Waarde',
            defaultValue: 'Standaard waarde',
            expression: 'Functie',
            format: 'Formule',
            scoresLocationGroup: 'Aantrekkelijkheden formule',
            scoresShop: 'Aantrekkelijkheden winkel',
            scoresRetailArea: 'Aantrekkelijkheden winkelgebied',
            retailArea: 'Winkelgebied',
            changedLocations: 'Wijzigingen',
            opening: 'Opening',
            closure: 'Sluiting',
            edited: "Aangepast",
            edit: "Bewerk",
            addScenario: 'Scenario toevoegen',
            infoScenario: 'Scenario informatie',
            calculate: 'Doorrekenen',
            add: 'Toevoegen',
            info: 'Informatie',
            scenarioCreated: 'Aanmaakdatum',
            scenarioModified: 'Bewerkdatum',
            scenarioCreatedBy: 'Aangemaakt door',
            scenarioModifiedBy: 'Bewerkt door',
            scenario: 'Scenario',
            scenarioReport: 'Impact rapport',
            scenarioName: 'Scenario naam',
            save: 'Opslaan',
            error: 'Fout',
            url: 'URL',
            status: 'Status',
            description: 'Omschrijving',
            settings: 'Instellingen',
            new: 'Nieuw',
            modelParameters: 'Model parameters',
            none: 'Geen',
            editModelParameters: 'Bewerk model parameters',
            editDrivers: 'Bewerk verklaringen',
            propertyType: 'Type',
            drawerEmptyText: 'Klik op een locatie op de kaart om informatie weer te geven.',
            score: 'Aantrekkelijkheid | Aantrekkelijkheden',
            information: 'Informatie',
            graphics: 'Grafieken',
            layers: 'Lagen',
            all: 'Alles',
            primary: 'Primair (50%)',
            secondary: 'Secundair (80%)',
            impact: 'Impact',
            impactCar: 'Impact auto',
            impactPedestrian: 'Impact voetganger',
            display: 'Weergave',
            top10: 'Top 10',
            top25: 'Top 25',
            top: 'Top',
            fraction: 'Aandeel',
            fractionCumulative: 'Aandeel (cumulatief)',
            showTravelTimes: 'Reistijd weergeven',
            travelTimes: 'Reistijd',
            minutes: 'Minuten',
            group: 'Group',
            property: 'Eigenschap',
            expressions: 'Formules',
            powerFactor: 'Machtsfactor',
            constant: 'Constante',
            scale: 'Schaal',
            max: 'Maximum',
            by: "door",
            fractionCutOffThreshold: 'Fractie drempel waarde',
            differenceDefaultScenario: 'Verschil t.o.v. standaard scenario',
            reports: 'Rapportage',
            locationsType1Name: 'Vestiging | Vestigingen',
            locationsType2Name: 'Winkelgebied | Winkelgebieden',
            locationsType1FilterLocations: 'Filter op vestiging',
            locationsType2FilterLocations: 'Filter op winkelgebied',
            googleSearch: 'Zoeken op de kaart',
            locationType1Scores: 'Aantrekkelijkheden vestiging',
            locationType2Scores: 'Aantrekkelijkheden winkelgebied',
            areaScores: 'Aantrekkelijkheden reistijd',
            locationsType1Impact: 'Impact per vestiging (top 25)',
            locationsType2Impact: 'Impact per winkelgebied (top 25)',
            locationGroupsType1Name: 'Formule',
            locationGroupsType2Name: 'Winkelgebied soort',
            locationsGroupsType1Impact: 'Impact per formule (top 10)',
            locationsGroupsType2Impact: 'Impact per winkelgebied soort (top 10)',
            locationsClustersType1Impact: 'Impact per winkelgebied (top 10)',
            locationsClustersType2Impact: 'Impact per gemeente (top 10)',
            locationsClustersType1EmptyName: '',
            locationsClustersType2EmptyName: '',
            locationsGroupsType1EmptyName: 'Zelfstandig',
            locationsGroupsType2EmptyName: '',
            locationClustersType1Name: 'Winkelgebied',
            locationClustersType2Name: 'Gemeente',
            outputType1Name: "Omzet per week",
            outputType2Name: "Standaard consumenten",
            outputType3Name: "Omzet per jaar",
            outputType4Name: "Standaard consumenten",
            areasType1Name: "Postcode | Postcodes",
            areasType2Name: "Statistische sector | Statisische sectoren",
            locationsType1New: "Nieuwe vestiging hier",
            locationsType2New: "Nieuwe winkelgebied hier",
            close: "Sluiten",
            delete: "Verwijderen",
            undoClose: "Sluiten ongedaan maken",
            modelFunctionTypeToUse: "Het is mogelijk de volgende velden te gebruiken:",
            ncalcMessage: "De functies maken gebruik van de NCalc bibliotheek. Kijk op https://github.com/ncalc/ncalc voor meer informatie.",
            calculationModelOutput: "Model resultaten",
            locationsOutput: 'Locations',
            scenarioLocationsOutput: 'Resultaten',
            scenarioLocations: 'Locaties',
            scenarioLocationsAllScenarios: 'Locaties van alle scenarios',
            scenarioLocationsOutputDescription: 'Resultaten van het geselecteerde scenario',
            scenarioLocationsOutputDescriptionWithHidden: 'Resultaten van het geselecteerde scenario alle velden',
            locationsOutputDescription: 'Locations.csv',
            catchmentAreaInformationExport: "Verzorgingsgebied",
            catchmentAreaInformationExportCarDescription: "Verzorgingsgebied auto van de geselecteerde locatie",
            catchmentAreaInformationExportPedestrianDescription: "Verzorgingsgebied voetganger van de geselecteerde locatie",
            areaInformationExport: "Gebied resultaat",
            areaInformationExportDescription: "Gebied resultaat van het geselecteerde gebied",
            weight: "Gewicht",
            editNormalizedScoresFunctions: "Bewerk functies genormaliseerde waarden",
            normalizedScoresFunctions: "Functies genormaliseerde waarden",
            before: "Voor",
            after: "Na",
            difference: "Verschil",
            localSituation: "Lokale situatie",
            dontShow: "Niet weergeven",
            pois: "POI's",
            matchingLocations: "Vergelijkbare locaties",
            scenarioStatusEdited: "Bewerkt",
            scenarioStatusWaiting: "Wachten op verwerking",
            scenarioStatusProcessing: "Bezig met verwerken",
            scenarioStatusCompleted: "Verwerkt",
            scenarioStatusCompletedWithErrors: "Verwerkt met fouten",
            pleaseLogin: "Meld u aan via de knop aan de rechterbovenkant.",
            busyLoading: "Bezig met laden..."
        }
    },
    en: {
        message: {
            scenarioLocationsVisibleOnMap: 'Locations of scenarios',
            scenarios: 'Scenarios',
            errorOccured: 'Oops! Something went wrong. An unexpected error has occurred, possibly due to a poor internet connection. Please close this message and attempt the action again. If the issue persists, kindly refresh the page. If the problem continues, consider checking your internet connection or contacting support for assistance.',
            noTopScenariosFound: 'There are no top scenarios found. Create a new top scenario or edit an existing scenario to be a top scenario.',
            filterScenarios: 'Name filter',
            locationInDefaultScenario: 'You cannot modify the default scenario. If you continue the location will be created in a new scenario with the same name as the location. Do you want to continue?',
            foundLocations: 'Found locations',
            generatedOn: 'Generated on',
            of: 'of',
            page: 'Page',
            selectAll: 'all',
            selectNone: 'none',
            heatMaps: 'Heat maps',
            print: 'Print',
            no: 'No',
            yes: 'Yes',
            copyChangesInformation: 'Please specify which changes you want to copy to the target scenario.',
            locations:'Locations',
            copyChanges: 'Copy changes',
            nonShared: 'Non shared',
            sharedWith: 'Shared with',
            otherLocations: 'other locations',
            mergeScenarioSource: 'The following changes will be merged into the selected scenario.',
            targetScenario: 'Target scenario',
            deleteSourceScenario: 'Delete source scenario',
            isTopScenarioDescription: 'A top scenario specifies the scenario is listed first in the list of scenarios and is highlighted bold.',
            isTopScenario: 'Top scenario',
            mergeScenario: 'Merge scenario',
            confirmClose: 'Are you sure you want to close this dialog?',
            displayName: 'Displayname',
            email: 'E-mail address',
            mobilePhone: 'Mobile phone',
            calculationModel: 'Calculation model',
            calculateUnprocessedScenarios: 'Process unprocessed scenarios',
            log: 'Log',
            eventType: 'Type',
            source: 'Source',
            message: 'Message',
            warning: 'Warning',
            fetchIsoLine: 'Fetch isoline',
            isoLine: 'Isoline',
            scheduledDateTimeUtc: 'Scheduled',
            isoLinesMissingDateTimeUtc: 'Missing drive times',
            startedDateTimeUtc: 'Start',
            endedDateTimeUtc: 'End',
            noMatchingLocations: 'No matching locations',
            scenarioNameIsRequired: 'Scenario name is required',
            parentScenario: 'Parent scenario',
            sourceLocations: 'Source locations',
            sourceLocationsAllScenarios: 'Source locations all scenarios',
            driverFilterFunction: 'Function',
            driverFilterTitle: 'Title',
            addDriver: 'Add driver',
            addDriverFilter: 'Add group',
            confirmRemoveScenario: 'Are you sure you want to delete this scenario?',
            confirmRemoveLocation: 'Are you sure you want to delete this location?',
            confirmUndoCloseLocation: 'Are you sure you want to undo the closing of this location?',
            confirmUndoEditLocation: 'Are you sure you want to undo the editing of this location?',
            editScenario: 'Edit scenario',
            removeScenario: 'Remove scenario',
            calculateScenario: 'Calculate scenario',

            filterAndSearch: 'Filter and search',
            map: 'Map',
            matchingPercentage: 'Matching %',
            drawerNotCalculated: 'The model is not calculated. Model results are unavailable.',
            drawerCalculating: 'The model is calculating. Wait for the results to be available.',
            legend: 'Legend',
            modelAdmin: 'Model administrator',
            globalAdmin: 'Global administrator',
            noChanges: 'The are no openings or closures.',
            name: 'Name',
            query: 'Search text',
            export: 'Export',
            noDrivers: 'There are no drivers defined for this model.',
            condition: 'Condition',
            roundingDigits: 'Decimals',
            inverse: 'Inverse',
            drivers: 'Drivers',
            report: 'report',
            matchingLocationsOutputChart: 'Output diagram',
            matchingLocationsSimilarityChart: 'Radar diagram',
            driversChart: 'Drivers diagram',
            disclaimer :'Disclaimer',
            disclaimerContent: 'IRIS draws on the latest scientific methods, current point of sale information, market data, and consumer insights. Our predictive models are estimated with, and validated against, historical revenue data. Any predictive model, no matter how advanced, is a simplified representation of reality and thus will never achieve 100% accuracy. No rights can be derived from the model outputs.',
            help: 'Manual',
            csvExport: 'Export data',
            fileFormatCulture: 'CSV language settings',
            dutchFormat: 'Dutch format',
            englishFormat: 'English format',
            areaInformation: 'Area information',
            locationAreas: 'Areas of the location',
            adminPages: 'Admin',
            graphics: 'Charts',
            adminOnly: 'Only for administrators',
            scenarioCompetition: 'Locations created in this scenario compete with eachother.',
            scenarioProcessingLog: 'Processing log',
            calculationScheduled: 'Processing scheduled',
            calculationWaitingForIsolines: 'Processing waiting for isolines',
            calculationStarted: 'Processing started',
            calculationEnded: 'Processing ended',
            calculationScenarioError: 'Processing error (scenario)',
            calculationModelError: 'Processing error (model)',
            isolinesStarted: 'Isolines retrieval started',
            isolinesEnded: 'Isolines retrieval ended',
            isolinesError: 'Isolines retrieval error',
            isolinesBusy: 'Isolines retrieval busy',
            dateAndTime: 'Date and time',
            action: 'Action',
            model: 'Model',
            user: 'User',
            last100records: 'Last 100 records',
            propertiesChart: 'XY chart',
            propertyX: 'Property on X axis',
            propertyY: 'Property on Y axis',
            applicationTitle: 'IRIS',
            title: 'Title',
            logout: 'Logout',
            login:'Login',
            share: 'Share',
            area: 'Zipcode | Zipcodes',
            personType: 'Person type',
            inhabitants: 'Inhabitants',
            houseHolds: 'Housholds',
            income: 'Income',
            areaRevenuePerYear: 'Expense in zipcode per year',
            areaRevenuePerPeriod: 'Expense in zipcode per period',
            areaStandardConsumers: 'Standard consumers in zipcode',
            total: 'Total',
            travelTimeScores: 'Attractions travel time',
            from: 'from',
            calculation: 'Calculation',
            perYear: 'Per year',
            catchmentArea: 'Catchment area',
            outputSpending: 'Expense',
            outputNumber: 'Number',
            car: 'Car',
            pedestrian: 'Pedestrian',
            scorePersonType: 'Attraction person type',
            calculated: 'Calculated',
            location: 'Location',
            value: 'Value',
            defaultValue: 'Default value',
            expression: 'Function',
            format: 'Format',
            scoresLocationGroup: 'Attractions format',
            scoresShop: 'Attractions shop',
            scoresRetailArea: 'Attractions retail area',
            retailArea: 'Retail area',
            changedLocations: 'Changes',
            opening: 'Opening',
            closure: 'Closure',
            add:'Add',
            edit: "Edit",
            edited: "Edited",
            addScenario: 'Add scenario',
            infoScenario: 'Scenario information',
            info:'Information',
            calculate: 'Calculate',
            scenarioCreated: 'Creation date',
            scenarioModified: 'Edit date',
            scenarioCreatedBy: 'Created by',
            scenarioModifiedBy: 'Edited by',
            scenario: 'Scenario',
            scenarioName: 'Scenario name',
            save: 'Save',
            error: 'Error',
            url: 'URL',
            status: 'Status',
            description: 'Description',
            settings: 'Settings',
            new: 'New',
            modelParameters: 'Model parameters',
            scenarioReport: 'Impact report',
            editModelParameters: 'Edit model parameters',
            editDrivers: 'Edit drivers',
            propertyType: 'Type',
            drawerEmptyText: 'Click a location on the map to display information.',
            score: 'Attraction | Attractions',
            information: 'Information',
            layers: 'Layers',
            all: 'Everything',
            primary: 'Primary (50%)',
            secondary: 'Secondary (80%)',
            impact: 'Impact',
            impactCar: 'Impact car',
            impactPedestrian: 'Impact pedestrian',
            display: 'Display',
            top10: 'Top 10',
            top25: 'Top 25',
            top: 'Top',
            fraction: 'Share',
            fractionCumulative: 'Share (cumulative)',
            showTravelTimes: 'Show travel times',
            travelTimes: 'Travel times',
            minutes: 'Minutes',
            group: 'Group',
            property: 'Property',
            expressions: 'Expressions',
            powerFactor: 'Power factor',
            constant: 'Constant',
            scale: 'Scale',
            max: 'Maximum',
            fractionCutOffThreshold: 'Fraction cut off threshold',
            differenceDefaultScenario: 'Difference default scenario',
            reports: 'Reports',
            none: 'None',
            locationsType1Name: 'Location | Locations',
            locationsType2Name: 'Retail area | Retail areas',
            locationsType1FilterLocations: 'Filter on location',
            locationsType2FilterLocations: 'Filter on retail area',
            googleSearch: 'Search the map',
            locationType1Scores: 'Attractions location',
            locationType2Scores: 'Attractions retail area',
            areaScores: 'Attractions travel times',
            locationsType1Impact: 'Impact per location (top 25)',
            locationsType2Impact: 'Impact per retail area (top 25)',
            locationsGroupsType1Impact: 'Impact per format',
            locationsGroupsType2Impact: 'Impact per retail area type',
            locationsClustersType1Impact: 'Impact per retail area',
            locationsClustersType2Impact: 'Impact per municipality',
            locationsGroupsType1EmptyName: 'Independent',
            locationsGroupsType2EmptyName: '',
            locationsClustersType1EmptyName: '',
            locationsClustersType2EmptyName: '',
            locationGroupsType1Name: 'Format',
            locationGroupsType2Name: 'Retail area type',
            locationClustersType1Name: 'Retail area',
            locationClustersType2Name: 'Municipality',
            outputType1Name: "Turnover per week",
            outputType2Name: "Standard consumers",
            outputType3Name: "Turnover per year",
            outputType4Name: "Standard consumers",
            areasType1Name: "Zipcode | Zipcodes",
            areasType2Name: "Statistical sector | Statistical sectors",
            locationsType1New: "New location here",
            locationsType2New: "New retail area here",
            close: "Close",
            delete: "Delete",
            undoClose: "Undo close",
            modelFunctionTypeToUse: "The following fields are possible to use:",
            ncalcMessage: "The functions are using the NCalc library. See https://github.com/ncalc/ncalc for more information.",
            calculationModelOutput: "Model results",
            locationsOutput: 'Locations',
            scenarioLocations: 'Locations',
            scenarioLocationsAllScenarios: 'Locations of all scenarios',
            scenarioLocationsOutput: 'Results',
            scenarioLocationsOutputDescription: 'Results of the selected scenario',
            scenarioLocationsOutputDescriptionWithHidden: 'Results of the selected scenario all fields',
            locationsOutputDescription: 'Locations.csv',
            catchmentAreaInformationExport: "Catchment area",
            catchmentAreaInformationExportCarDescription: "Catchment area car of the selected location",
            catchmentAreaInformationExportPedestrianDescription: "Catchment area pedestrian of the selected location",
            areaInformationExport: "Area result",
            areaInformationExportDescription: "Area result of the selected area",
            weight: "Weight",
            editNormalizedScoresFunctions: "Edit normalized scores functions",
            normalizedScoresFunctions: "Normalized scores functions",
            before: "Before",
            after: "After",
            difference: "Difference",
            localSituation: "Local situation",
            dontShow: "Don't show",
            pois: "POI's",
            by:"by",
            matchingLocations: "Matching locations",
            scenarioStatusEdited: "Edited",
            scenarioStatusWaiting: "Waiting for processing",
            scenarioStatusProcessing: "Processing",
            scenarioStatusCompleted: "Completed",
            scenarioStatusCompletedWithErrors: "Completed with errors",
            pleaseLogin: "Please login using the upper right menu.",
            busyLoading: "Busy loading..."
        }
    }
}
export default messages;
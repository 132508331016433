import currencyFilter from '@/filters/currency.filter';
import fixedNumberFilter from '@/filters/fixedNumber.filter';

export const mutations = {
    SET_MODELS(state: any, models: any) {
        state.models = models;
    },
    SET_MODEL(state: any, model: any) {
        state.model = model;
    },
    SET_FILTER(state: any, filterLocations: any) {
        state.filterLocations = filterLocations;
    },
    SET_FILTERSCENARIOS(state: any, filterScenarios: any) {
        state.filterScenarios = filterScenarios;
    },
    SET_STANDBYFILTERLOCATIONS(state: any, standByFilterLocations: any) {
        state.standByFilterLocations = standByFilterLocations;
    },
    SET_STANDBYFILTERSCENARIOS(state: any, standByFilterScenarios: any) {
        state.standByFilterScenarios = standByFilterScenarios;
    },
    SET_SCENARIOLOCATIONSVISIBLE(state: any, scenarioLocationsVisible: boolean) {
        state.scenarioLocationsVisible = scenarioLocationsVisible;
    },
    SET_SUBMODELS(state: any, subModels: any) {
        state.subModels = subModels;
    },
    SET_SUBMODEL(state: any, subModel: any) {
        state.subModel = subModel;
    },
    SET_SCENARIOS(state: any, scenarios: any) {
        state.scenarios = scenarios;
    },
    SET_SCENARIOLOCATIONS(state: any, scenarioLocations: any) {
        state.scenarioLocations = scenarioLocations;
    },
    SET_LOG(state: any, log: any) {
        state.log = log;
    },
    SET_AREASETSVISIBLE(state: any, areaSetsVisible: any) {
        state.areaSetsVisible = areaSetsVisible;
    },
    SET_POISETSVISIBLE(state: any, poiSetsVisible: any) {
        state.poiSetsVisible = poiSetsVisible;
    },
    SET_HEATMAPSETSVISIBLE(state: any, heatMapSetsVisible: any) {
        state.heatMapSetsVisible = heatMapSetsVisible;
    },
    SET_SCENARIO(state: any, scenario: any) {
        state.scenario = scenario;
    },
    SET_DRIVERS(state: any, drivers: any) {
        state.drivers = drivers;
    },
    SET_SCENARIODIFFERENCES(state: any, scenarioDifferences: any) {
        state.scenarioDifferences = scenarioDifferences;
    },
    SET_LOCATIONS(state: any, locations: any) {
        state.locations = locations;
        
        state.locationsNotExcluded = locations?.filter((location: any) => !location.excluded);
    },
    SET_LOCATIONDRIVERS(state: any, locationDrivers: any) {
        state.locationDrivers = locationDrivers;
    },
    SET_LOCATIONPHOTO(state: any, locationPhoto: any) {
        state.locationPhoto = locationPhoto;
    },
    SET_MATCHINGLOCATIONS(state: any, matchingLocations: any) {
        state.matchingLocations = matchingLocations;
    },
    SET_MATCHINGSCORES(state: any, matchingScores: any) {
        state.matchingScores = matchingScores;
    },
    SET_PROPERTIESCHART(state: any, propertiesChart: any) {
        state.propertiesChart = propertiesChart;
    },
    SET_LOCATION(state: any, location: any) {
        state.location = location;
    },
    SET_LOCATIONPROPERTIES(state: any, locationProperties: any) {
        state.locationProperties = locationProperties;
    },
    SET_LOCATIONPROPERTIESOLD(state: any, locationProperties: any) {
        state.locationPropertiesOld = locationProperties;
    },
    SET_LOCATIONSCORES(state: any, locationScores: any) {
        state.locationScores = locationScores;
    },
    SET_LOCATIONISOLINES(state: any, locationIsoLines: any) {
        state.locationIsoLines = locationIsoLines;
    },
    SET_LOCATIONTRAVELTIMEMODE(state: any, locationTravelTimeMode: any) {
        state.locationTravelTimeMode = locationTravelTimeMode;
    },
    SET_LOCATIONTRAVELTIMEMINUTES(state: any, locationTravelTimeMinutes: any) {
        state.locationTravelTimeMinutes = locationTravelTimeMinutes;
    },
    SET_LOCATIONCATCHMENTAREA(state: any, locationCatchmentArea: any) {
        state.locationCatchmentArea = locationCatchmentArea;
    },
    SET_LOCATIONCATCHMENTAREAMODE(state: any, locationCatchmentAreaMode: any) {
        state.locationCatchmentAreaMode = locationCatchmentAreaMode;
    },
    SET_AREA(state: any, area: any) {
        state.area = area;
    },
    SET_MODELPARAMETERS(state: any, modelParameters: any) {
        state.modelParameters = modelParameters;
    },
    SET_SCENARIOPROCESSINGLOG(state: any, scenarioProcessingLog: any) {
        state.scenarioProcessingLog = scenarioProcessingLog;
    },
    SET_NORMALIZEDSCORESFUNCTIONS(state: any, normalizedScoresFunctions: any) {
        state.normalizedScoresFunctions = normalizedScoresFunctions;
    },
    SET_LOCATIONEDITING(state: any, locationEditing: any) {
        state.locationEditing = locationEditing;
    },
    SET_SCENARIOEDITING(state: any, scenarioEditing: any) {
        state.scenarioEditing = scenarioEditing;
    },
    SET_SCENARIOMERGING(state: any, scenarioMerging: any) {
        state.scenarioMerging = scenarioMerging;
    },
    SET_SEARCHRESULTS(state: any, searchResults: any) {
        state.searchResults = searchResults;
    },
    SET_MAPBOUNDS(state: any, mapBounds: any) {
        state.mapBounds = mapBounds;
    },
    SET_POIS(state: any, pois: any) {
        state.pois = pois;
    },
    SET_HEATMAPPOINTS(state: any, heatMapPoints: any) {
        state.heatMapPoints = heatMapPoints;
    },
    SET_ISOLINEPARAMETERS(state: any, isoLineParameters: any) {
        state.isoLineParameters = isoLineParameters;
    },

    
};
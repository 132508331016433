import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { AdminState } from './types';
import { RootState } from '../types';

export const state: AdminState = {
    users: [],
    selectedUser: null,
    userModels: [],
    selectedModel: null,
    modelUsers: [],
    models: [],
    logins: [],
    loginEditing: { 
        displayName: '',
        email: '',
    },
};

const namespaced = true;

export const admin: Module<AdminState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export const getters = {
    getUsers(state: any): object {
        return state.users;
    },
    getLogins(state: any): object {
        return state.logins;
    },
    getSelectedUser(state: any): object {
        return state.selectedUser;
    },
    getUserModels(state: any): object {
        return state.userModels;
    },
    getSelectedModel(state: any): object {
        return state.selectedModel;
    },
    getModelUsers(state: any): object {
        return state.modelUsers;
    },
    getModels(state: any): object {
        return state.models;
    },
    getLoginEditing(state: any): object {
        return state.loginEditing;
    },
};
